import logoSignalIduna from "../../../assets/images/Logo_SignalIduna.svg";
const logoHamburgerFeuerkasse = require("../../../assets/images/Logo_HFK.jpg");
const logoProvinzialGruen = require("../../../assets/images/Logo_PV.jpg");
const logoProvinzialBlau = require("../../../assets/images/Logo_PNB.jpg");
const logoLVM = require("../../../assets/images/Logo_LVM.png");
const logoItzehoer = require("../../../assets/images/Logo_Itzehoer.png");
const logoVHV = require("../../../assets/images/Logo_VHV.png");
const logoMecklenburgische = require("../../../assets/images/Logo_Mecklenburgische.png");

interface InsuranceInformation {
  image: string;
  alt: string;
  unfallNumber: string | null;
  haftNumber: string;
  width: string;
  height: string;
  datenschutztext1: string;
  datenschutztext2: string;
  datenschutzlink: string;
}

const insurances: InsuranceInformation[] = [
  {
    image: logoHamburgerFeuerkasse,
    alt: "Logo der Hamburger Feuerkasse Versicherungs-AG",
    unfallNumber: "040 / 30904 - 9856",
    haftNumber: "040 / 30904 - 4680",
    width: "197px",
    height: "52px",
    datenschutztext1: "www.hamburger-feuerkasse.de/",
    datenschutztext2: "datenschutz",
    datenschutzlink: "https://www.hamburger-feuerkasse.de/datenschutz",
  },
  {
    image: logoProvinzialGruen,
    alt: "Logo der Provinzial Versicherung AG",
    unfallNumber: "0251 / 219 - 9856",
    haftNumber: "0251 / 219 - 4680",
    width: "153px",
    height: "40px",
    datenschutztext1: "www.provinzial.de/",
    datenschutztext2: "datenschutz",
    datenschutzlink: "https://www.provinzial.de/datenschutz",
  },
  {
    image: logoProvinzialBlau,
    alt: "Logo der Provinzial Nord Brandkasse AG",
    unfallNumber: "0431 / 603 - 9856",
    haftNumber: "0431 / 603 - 4680",
    width: "153px",
    height: "40px",
    datenschutztext1: "www.provinzial.de/",
    datenschutztext2: "datenschutz",
    datenschutzlink: "https://www.provinzial.de/datenschutz",
  },
  {
    image: logoLVM,
    alt: "Logo der LVM Versicherung",
    unfallNumber: "0251 / 702 – 2289",
    haftNumber: "0251 / 702 - 3444",
    width: "111px",
    height: "48px",
    datenschutztext1: "www.lvm.de/die-lvm/unternehmen/wir-als-versicherer/",
    datenschutztext2: "datenschutz/datenschutz-uebersicht",
    datenschutzlink: "https://www.lvm.de/die-lvm/unternehmen/wir-als-versicherer/datenschutz/datenschutz-uebersicht",
  },
  {
    image: logoItzehoer,
    alt: "Logo der Itzehoer Versicherung",
    unfallNumber: null,
    haftNumber: "04821 / 773 - 155",
    width: "189px",
    height: "42px",
    datenschutztext1: "www.itzehoer.de/",
    datenschutztext2: "datenschutz",
    datenschutzlink: "https://www.itzehoer.de/datenschutz/",
  },
  {
    image: logoVHV,
    alt: "Logo der VHV Allgemeine Versicherung AG",
    unfallNumber: null,
    haftNumber: "Telefonnummer folgt",
    width: "142px",
    height: "45px",
    datenschutztext1: "www.vhv.de/meta/",
    datenschutztext2: "datenschutz",
    datenschutzlink: "https://www.vhv.de/meta/datenschutz",
  },
  {
    image: logoMecklenburgische,
    alt: "Logo der Mecklenburgische Versicherungs-Gesellschaft a.G.",
    unfallNumber: "0511 / 5351 - 9739",
    haftNumber: "0511 / 5351 - 9735",
    width: "206px",
    height: "50px",
    datenschutztext1: "https://www.mecklen burgische.de/",
    datenschutztext2: "datenschutz",
    datenschutzlink: "https://www.mecklenburgische.de/datenschutz",
  },
  {
    image: logoSignalIduna,
    alt: "Logo der Signal Iduna",
    unfallNumber: "0231 / 135 - 9767",
    haftNumber: "0231 / 135 - 3105",
    width: "200px",
    height: "auto",
    datenschutztext1: "https://legalinfo.signal-iduna.de/",
    datenschutztext2: "allgemein/datenschutz/",
    datenschutzlink: "https://legalinfo.signal-iduna.de/allgemein/datenschutz/",
  },
];

export default insurances;
